define('www/controllers/cms/search', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        actions: {
            search: function search() {
                if (this.get('search')) {
                    console.log(this.get('search'));
                }
            }
        }

    });
});
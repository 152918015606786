define('www/initializers/setup-pikaday-i18n', ['exports', 'ember'], function (exports, _ember) {
    // import moment from 'moment';

    exports['default'] = {
        name: 'setup-pikaday-i18n',
        initialize: function initialize(app) {
            var i18n = _ember['default'].Object.extend({
                // previousMonth: 'Previous month',
                // nextMonth: 'Next month',
                months: ["Janúar", "Febrúar", "Mars", "Apríl", "Maí", "Júní", "Júlí", "Ágúst", "September", "Október", "Nóvember", "Desember"],
                weekdays: ["Sunnudagur", "Mánudagur", "Þriðjudagur", "Miðvikudagur", "Fimmtudagur", "Föstudagur", "Laugardagur"],
                weekdaysShort: ["Sun", "Mán", "Þri", "Mið", "Fim", "Fös", "Lau"]
            });

            app.register('pikaday-i18n:main', i18n, { singleton: true });
            app.inject('component:pikaday-input', 'i18n', 'pikaday-i18n:main');
        }
    };
});
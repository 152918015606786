define('www/routes/error', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        setupController: function setupController() {
            var runLater = _ember['default'].run.later(this, function () {
                this.transitionTo('index');
            }, 12000);

            this.set('runLater', runLater);
        },

        actions: {
            willTransition: function willTransition() {
                _ember['default'].run.cancel(this.get('runLater'));
            }
        }

    });
});
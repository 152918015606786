define('www/models/admin', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
    var attr = _emberData['default'].attr;
    var Model = _emberData['default'].Model;
    exports['default'] = Model.extend({
        name: attr('string'),
        email: attr('string'),
        role: attr('string'),
        password: attr('string'),
        added: attr('string'),
        active: attr('boolean', { defaultValue: true }),

        register: (0, _emberApiActions.collectionAction)({ path: 'register', type: 'post' }),

        forgotpsw: (0, _emberApiActions.collectionAction)({ path: 'forgotpsw', type: 'post' }),
        changepsw: (0, _emberApiActions.collectionAction)({ path: 'changepsw', type: 'post' })
    });
});
define('www/controllers/login', ['exports', 'ember', 'ember-local-storage'], function (exports, _ember, _emberLocalStorage) {
    exports['default'] = _ember['default'].Controller.extend({

        loginManager: _ember['default'].inject.service(),
        currentUser: (0, _emberLocalStorage.storageFor)('current-user'),

        init: function init() {
            this.get('currentUser').reset();
        },

        actions: {
            login: function login() {
                var _this = this;

                this.get('loginManager').login(this.get('email'), this.get('password'), function (error) {
                    if (error) {
                        _this.set('errorMessage', error);
                    } else {
                        _this.set('errorMessage');
                        _this.transitionToRoute('index');
                    }
                });
            }
        }

    });
});
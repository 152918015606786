define('www/controllers/cms/usergroups/form', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        users: _ember['default'].computed('search', function () {
            return this.store.query('user', { query: this.get('search'), offset: 0, limit: 30 });
        }),

        observeSearch: _ember['default'].observer('searchSelected', function () {
            var _this = this;

            this.get('model.groupUsers').map(function (user) {
                if (!_this.get('searchSelected') || user.get('customerName').toLowerCase().includes(_this.get('searchSelected').toLowerCase())) {
                    user.set('hide', false);
                } else {
                    user.set('hide', true);
                }
            });
        }),

        formOk: _ember['default'].computed('model.groupName', 'model.groupUsers.length', 'model.imports', function () {
            return this.get('model.groupName') && (this.get('model.groupUsers.length') || this.get('model.imports'));
        }),

        parseCSV: function parseCSV(file) {
            file = file.replace(/\r/g, '');
            return file.split("\n").map(function (ssn) {
                return ssn = ssn.length === 9 ? '0' + ssn : ssn;
            }).filter(function (ssn) {
                return ssn.length;
            });
        },

        actions: {
            addUser: function addUser(user) {
                this.get('model.groupUsers').addObject(user);
            },

            removeUser: function removeUser(user) {
                this.get('model.groupUsers').removeObject(user);
            },

            didSelectFiles: function didSelectFiles(model, files) {
                var _this2 = this;

                if (files.length) {
                    var reader = new FileReader();
                    reader.readAsText(files[0]);
                    reader.addEventListener("load", function () {
                        model.set('imports', _this2.parseCSV(reader.result));
                        model.set('filename', files[0].name);
                    }, false);
                }
            }
        }

    });
});
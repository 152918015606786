define('www/router', ['exports', 'ember', 'www/config/environment'], function (exports, _ember, _wwwConfigEnvironment) {

    var Router = _ember['default'].Router.extend({
        location: _wwwConfigEnvironment['default'].locationType,
        rootURL: _wwwConfigEnvironment['default'].rootURL
    });

    Router.map(function () {

        // Public

        this.route('login');
        this.route('forgotpsw');

        // Public: Company reports

        this.route('company', { path: "c" }, function () {
            this.route('report', { path: ":companyId" });
        });

        // CMS

        this.route('cms', function () {
            this.route('search');

            this.route('users', function () {
                this.route('edit', { path: ":user_id" });
                this.route('new');
            });

            this.route('usergroups', function () {
                this.route('edit', { path: ":group_id" });
                this.route('new');
            });

            this.route('companies', function () {
                this.route('edit', { path: ":company_id" });
                this.route('new');
            });

            this.route('offers', function () {
                this.route('edit', { path: ":offer_id" });
                this.route('new');
            });

            this.route('notifications', function () {
                this.route('edit', { path: ":notification_id" });
                this.route('new');
            });

            this.route('pictures');

            this.route('admins', function () {
                this.route('edit', { path: ":admin_id" });
                this.route('new');
            });
        });

        // 404

        this.route('error', { path: "/*path" });
    });

    exports['default'] = Router;
});
define('www/controllers/cms/users/form', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
    exports['default'] = _ember['default'].Controller.extend({

        today: (0, _moment['default'])().toDate(),

        ssnOk: _ember['default'].computed('model.ssn', function () {
            return this.get('model.ssn.length') === 10;
        }),

        phoneOk: _ember['default'].computed('model.phone', function () {
            return this.get('model.phone.length') === 7;
        }),

        formOk: _ember['default'].computed('model.customerName', 'model.ssn', 'model.email', 'model.phone', function () {
            return this.get('model.customerName') && this.get('model.ssn.length') === 10 && this.get('model.phone.length') === 7 && this.get('model.email');
        }),

        actions: {
            selectBank: function selectBank(answer) {
                var arion = answer === "1" ? true : false;
                this.set('model.arionCustomer', arion);
            },

            selectDate: function selectDate(date) {
                this.set('model.expiryDate', (0, _moment['default'])(date).format('x'));
            }
        }

    });
});
define('www/serializers/group', ['exports', 'ember-data', 'www/serializers/application'], function (exports, _emberData, _wwwSerializersApplication) {
    exports['default'] = _wwwSerializersApplication['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {
        attrs: {
            groupUsers: { embedded: 'always' }
        },

        normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
            if (payload.userGroup && payload.importStats) {
                var importStats = payload.importStats;
                payload = payload.userGroup;
                payload.importStats = importStats;
            }
            return this._super(store, primaryModelClass, payload, id, requestType);
        }
    });
});
define('www/controllers/cms/pictures', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        actions: {
            didSelectFiles: function didSelectFiles(model, files) {
                if (files.length) {
                    var reader = new FileReader();
                    reader.readAsDataURL(files[0]);

                    reader.addEventListener("load", function () {
                        model.set('url', reader.result);
                    }, false);
                }
            }
        }

    });
});
define('www/mixins/prevent-save-adapter', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    preventSave: false,

    updateRecord: function updateRecord(store, type, snapshot) {
      if (!this.get('preventSave')) {
        return this._super(store, type, snapshot);
      }

      this.set('preventSave', false);
      return true;
    }
  });
});
define('www/services/api-manager', ['exports', 'ember', 'www/config/environment', 'ember-local-storage'], function (exports, _ember, _wwwConfigEnvironment, _emberLocalStorage) {
    var RESTURL = _wwwConfigEnvironment['default'].RESTURL;
    var NAMESPACE = _wwwConfigEnvironment['default'].NAMESPACE;
    exports['default'] = _ember['default'].Service.extend({

        currentUser: (0, _emberLocalStorage.storageFor)('current-user'),

        headers: _ember['default'].computed("currentUser.user.token", function () {
            return {
                "Accept": "application/json, text/javascript, */*; q=0.01",
                "Content-Type": "application/json",
                "Authorization": this.get("currentUser.user.token") ? "Bearer " + this.get("currentUser.user.token") : ''
            };
        }),

        request: function request(data) {
            var _this = this;

            return new _ember['default'].RSVP.Promise(function (resolve, reject) {

                var jqxhr = _ember['default'].$.ajax({
                    type: data.type ? data.type : 'get',
                    url: RESTURL + '/' + NAMESPACE + data.url,
                    contentType: 'application/json',
                    dataType: 'json',
                    data: JSON.stringify(data.data),
                    headers: _this.get('headers')
                }).fail(function () {
                    reject(jqxhr.responseJSON);
                }).done(function () {
                    resolve(jqxhr.responseJSON);
                });
            });
        },

        // POST request with company token.
        // Used for company reports outside of the cms, without auth header (in most cases).
        getCompanyOffers: function getCompanyOffers(companyToken, companyId) {
            var _this2 = this;

            return new _ember['default'].RSVP.Promise(function (resolve, reject) {

                var data = {
                    url: '/analytics/companies/' + companyId + '/offers',
                    type: 'POST',
                    data: {
                        companyToken: companyToken
                    }
                };

                _this2.request(data).then(function (response) {
                    resolve(response.offerAnalytics);
                })['catch'](function (error) {
                    reject(error);
                });
            });
        },

        getCompanyData: function getCompanyData(companyToken, companyId) {
            var _this3 = this;

            return new _ember['default'].RSVP.Promise(function (resolve, reject) {

                var data = {
                    url: '/analytics/companies/' + companyId + '/offers',
                    type: 'POST',
                    data: {
                        companyToken: companyToken
                    }
                };

                _this3.request(data).then(function (response) {
                    // We don't want the whole object as it sends also array of all company offers.
                    resolve({ companyLogo: response.companyLogo, companyName: response.companyName });
                })['catch'](function (error) {
                    reject(error);
                });
            });
        },

        getOfferReport: function getOfferReport(companyToken, companyId, offerId) {
            var _this4 = this;

            return new _ember['default'].RSVP.Promise(function (resolve, reject) {

                var data = {
                    url: '/analytics/companies/' + companyId + '/offers/' + offerId + '/report',
                    type: 'POST',
                    data: {
                        companyToken: companyToken
                    }
                };

                _this4.request(data).then(function (response) {
                    resolve(response);
                })['catch'](function (error) {
                    reject(error);
                });
            });
        }

    });
});
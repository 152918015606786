define('www/models/company', ['exports', 'ember', 'ember-data', 'ember-data/relationships'], function (exports, _ember, _emberData, _emberDataRelationships) {
    var attr = _emberData['default'].attr;
    var Model = _emberData['default'].Model;
    exports['default'] = Model.extend({
        name: attr('string'),
        ssn: attr('string'),
        url: attr('string'),
        logoUrl: attr('string'),
        reportToken: attr('string'),

        companyStores: (0, _emberDataRelationships.hasMany)('companyStore'),

        reportUrl: _ember['default'].computed('reportToken', function () {
            return '/c/' + this.get('id') + '?token=' + this.get('reportToken');
        })
    });
});
define("www/templates/components/x-checkbox", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "www/templates/components/x-checkbox.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("input");
        dom.setAttribute(el1, "type", "checkbox");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode(" ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("label");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        if (this.cachedFragment) {
          dom.repairClonedNode(element0, [], true);
        }
        var element1 = dom.childAt(fragment, [2]);
        var morphs = new Array(7);
        morphs[0] = dom.createAttrMorph(element0, 'id');
        morphs[1] = dom.createAttrMorph(element0, 'class');
        morphs[2] = dom.createAttrMorph(element0, 'checked');
        morphs[3] = dom.createAttrMorph(element0, 'onchange');
        morphs[4] = dom.createAttrMorph(element0, 'disabled');
        morphs[5] = dom.createAttrMorph(element1, 'for');
        morphs[6] = dom.createMorphAt(element1, 0, 0);
        return morphs;
      },
      statements: [["attribute", "id", ["concat", ["x-", ["get", "id", ["loc", [null, [1, 15], [1, 17]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["get", "class", ["loc", [null, [1, 29], [1, 34]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "checked", ["get", "checked", ["loc", [null, [1, 63], [1, 70]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onchange", ["subexpr", "action", ["toggleChecked"], ["value", "target.value"], ["loc", [null, [null, null], [1, 129]]], 0, 0], 0, 0, 0, 0], ["attribute", "disabled", ["get", "disabled", ["loc", [null, [1, 141], [1, 149]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "for", ["concat", ["x-", ["get", "id", ["loc", [null, [1, 170], [1, 172]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "text", ["loc", [null, [1, 176], [1, 184]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});
define('www/serializers/application', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].JSONSerializer.extend({
        serialize: function serialize(snapshot, options) {
            var json = this._super(snapshot, options);
            if (json.id) {
                json.id = parseInt(json.id);
            }
            return json;
        }
    });
});
define('www/models/company-store', ['exports', 'ember', 'ember-data', 'ember-data/relationships'], function (exports, _ember, _emberData, _emberDataRelationships) {
    var attr = _emberData['default'].attr;
    var Model = _emberData['default'].Model;
    exports['default'] = Model.extend({
        address: attr('string'),
        latitude: attr('string'),
        longitude: attr('string'),
        active: attr('boolean', { defaultValue: true }),

        offers: (0, _emberDataRelationships.hasMany)('offer'),

        isValid: _ember['default'].computed('address', 'latitude', 'longitude', function () {
            return this.get('address') && this.isGeoloc(this.get('latitude')) && this.isGeoloc(this.get('longitude'));
        }),

        isGeoloc: function isGeoloc(value) {
            var match = value ? value.match(/^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}/) : null;
            return match ? match[0] : null;
        }
    });
});
define('www/routes/cms/admins/edit', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    var Route = _ember['default'].Route;
    exports['default'] = Route.extend({

        loginManager: service(),
        controllerName: 'cms/admins/form',

        renderTemplate: function renderTemplate() {
            this.render('cms/admins/form');
        },

        actions: {

            save: function save() {
                var _this = this;

                if (this.controller.get('model.password')) {
                    this.controller.set('model.password', this.get('loginManager').hashPsw(this.controller.get('model.password')));
                }

                this.controller.get('model').save().then(function () {
                    _this.transitionTo('cms.admins');
                })['catch'](function (response) {
                    console.log(response);
                    _this.controller.set('errorMessage', response);
                });
            },

            remove: function remove(model) {
                var _this2 = this;

                model.destroyRecord()['finally'](function () {
                    _this2.transitionTo('cms.admins');
                });
            },

            willTransition: function willTransition() {
                this.controller.get('model').rollbackAttributes();
                this.controller.set('model.password');
            },

            didTransition: function didTransition() {
                this.controllerFor('cms').set('hideMenu', true);
            }
        }

    });
});
define('www/controllers/cms/notifications/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        queryParams: ['type', 'search', 'page'],
        search: "",
        page: 0,

        scrollUp: function scrollUp() {
            _ember['default'].$("html, body").animate({ scrollTop: _ember['default'].$('.bold').position().top }, 200);
        },

        actions: {
            loadLess: function loadLess() {
                this.set('page', this.get('page') - 1);
                this.scrollUp();
            },
            loadMore: function loadMore() {
                this.set('page', this.get('page') + 1);
                this.scrollUp();
            },
            search: function search() {}
        }

    });
});
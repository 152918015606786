define('www/controllers/company/report', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Controller.extend({

        apiManager: service(),

        filter: {
            date_key: 'last24h',
            dates: [{
                key: 'last24h',
                name: 'Í dag',
                range: 'Í dag - ' + (0, _moment['default'])().format('Do MMMM YYYY')
            }, {
                key: 'last7days',
                name: 'Síðustu sjö dagar',
                range: (0, _moment['default'])().subtract(7, 'days').calendar() + ' - ' + (0, _moment['default'])().format('Do MMMM YYYY')
            }, {
                key: 'allTheTime',
                name: 'Frá upphafi',
                range: 'Frá upphafi'
            }]
        },

        offerReport: _ember['default'].observer('filter.offer_id', 'filter.date_key', function () {
            this.send('loadOfferReport', this.get('filter.offer_id'), this.get('filter.date_key'));
        }),

        validFrom: _ember['default'].observer('currentOffer.validFrom', function () {
            if (this.get('currentOffer.validFrom')) {
                return (0, _moment['default'])().format('Do MMMM YYYY');
            }
        }),

        date: _ember['default'].computed('filter.date_key', function () {
            var dateFilter = this.get('filter.dates').filterBy('key', this.get('filter.date_key'));
            return dateFilter.length ? dateFilter[0].range : null;
        }),

        currentOffer: _ember['default'].computed('filter.offer_id', function () {
            return this.store.peekRecord('offer', this.get('filter.offer_id'));
        }),

        actions: {
            selectOffer: function selectOffer(offerId) {
                this.set('filter.offer_id', offerId);
            },

            selectDate: function selectDate(date_key) {
                this.set('filter.date_key', date_key);
            }
        }

    });
});
define('www/routes/cms/users/edit', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        controllerName: "cms/users/form",

        renderTemplate: function renderTemplate() {
            this.render('cms/users/form');
        },

        actions: {

            save: function save() {
                var _this = this;

                this.controller.get('model').save().then(function () {
                    _this.transitionTo('cms.users');
                })['catch'](function (response) {
                    console.log(response);
                    _this.controller.set('errorMessage', response);
                });
            },

            willTransition: function willTransition() {
                this.controller.get('model').rollbackAttributes();
            },

            didTransition: function didTransition() {
                this.controllerFor('cms').set('hideMenu', true);
            }
        }

    });
});
define('www/controllers/cms/notifications/form', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
    exports['default'] = _ember['default'].Controller.extend({

        queryParams: ['query'],
        today: (0, _moment['default'])().toDate(),
        hour: '00',
        minutes: '00',
        date: null,

        /*
            User can only choose processDate that is 60 minutes in the future.
            If processDate is set to null or undefined the notification will be sent instantly.
        */

        minProcessDateMinutes: 60,

        filter: {
            notificationTypes: [{
                key: 'SIMPLE_TEXT',
                value: 'Almenn tilkynning'
            }, {
                key: 'NEW_OFFERS_IN_CATEGORY',
                value: 'Ný tilboð úr flokki'
            }, {
                key: 'SPECIFIC_OFFER',
                value: 'Eitt tilboð'
            }],

            operatingSystems: [{
                key: 'BOTH',
                value: 'Android + iOS'
            }, {
                key: 'ANDROID',
                value: 'Android'
            }, {
                key: 'IOS',
                value: 'iOS'
            }]
        },

        hoursArray: _ember['default'].computed(function () {
            var result = [];
            for (var i = 0; i < 24; i++) {
                var toPush = i < 10 ? '0' + i.toString() : i.toString();
                result.push(toPush);
            }
            return result;
        }),

        minutesArray: _ember['default'].computed(function () {
            var result = [];
            for (var i = 0; i < 60; i++) {
                var toPush = i < 10 ? '0' + i.toString() : i.toString();
                result.push(toPush);
            }
            return result;
        }),

        validateDate: _ember['default'].computed('date', 'hour', 'minutes', function () {
            if (!this.get('date')) {
                this.set('model.notification.processDate');
                return true;
            }

            var date = this.get('date');
            var hour = this.get('hour');
            var minutes = this.get('minutes');
            var newDate = (0, _moment['default'])(date).add(Number(hour), 'h').add(Number(minutes), 'm');

            if (newDate.diff((0, _moment['default'])(), 'minutes') < Number(this.get('minProcessDateMinutes'))) {
                this.set('model.notification.processDate');
                return false;
            }

            this.set('model.notification.processDate', newDate.valueOf());
            return true;
        }),

        formOk: _ember['default'].computed('model.notification.notificationType', 'model.notification.message', 'validateDate', 'model.notification.os', 'model.notification.value', function () {

            if (this.get('model.notification.notificationType') !== 'SIMPLE_TEXT' && !this.get('model.notification.value')) {
                return false;
            }

            if (!this.get('model.notification.message') || !this.get('validateDate') || !this.get('model.notification.os')) {
                return false;
            }

            return true;
        }),

        currentCategory: _ember['default'].computed('filter.notificationCategoryId', function () {
            if (this.get('filter.notificationCategoryId')) {
                var category = this.store.peekRecord('category', this.get('filter.notificationCategoryId'));
                console.log(category.get('newOffersCount'));
                return this.store.peekRecord('category', this.get('filter.notificationCategoryId'));
            }
        }),

        resetValues: _ember['default'].observer('model.notification.notificationType', function () {
            this.set('model.notification.value');
            this.set('filter.notificationCategoryId');
            this.set('filter.notificationSpecificOfferId');
        }),

        filterOffers: _ember['default'].observer('query', function () {
            var _this = this;

            this.store.query('offer', { active: 1, all: 1, limit: 30, offset: 0, query: this.get('query') }).then(function (result) {
                _this.set('offers', result);
                _this.set('filter.notificationSpecificOfferId', result.get('firstObject.id'));
                _this.set('model.notification.value', result.get('firstObject.id'));
            });
        }),

        resetForm: function resetForm() {
            this.get('store').unloadAll();
            this.set('date');
            this.set('hour');
            this.set('minutes');
            this.set('filter.notificationCategoryId');
            this.set('filter.notificationSpecificOfferId');
            this.set('query');
        },

        actions: {
            selectNotificationType: function selectNotificationType(type) {
                this.set('model.notification.notificationType', type);
            },

            selectNotificationCategory: function selectNotificationCategory(categoryId) {
                this.set('filter.notificationCategoryId', categoryId);
                this.set('model.notification.value', categoryId);
            },

            selectSpecificOffer: function selectSpecificOffer(offerId) {
                this.set('filter.notificationSpecificOfferId', offerId);
                this.set('model.notification.value', offerId);
            },

            selectDate: function selectDate(date) {
                this.set('date', date);
            },

            selectOS: function selectOS(os) {
                this.set('model.notification.os', os);
            },

            setHour: function setHour(hour) {
                this.set('hour', hour);
            },

            setMinutes: function setMinutes(minutes) {
                this.set('minutes', minutes);
            },

            resetDate: function resetDate() {
                this.set('date');
                this.set('hour');
                this.set('minutes');
                this.set('model.notification.processDate');
            },

            goBack: function goBack() {
                this.resetForm();
                this.transitionToRoute('cms.notifications');
            },

            toggleGroup: function toggleGroup(group) {
                var groups = this.get('model.notification.groups');

                if (groups.includes(group)) {
                    groups.removeObject(group);
                } else {
                    groups.pushObject(group);
                }
            }
        }

    });
});
define('www/routes/cms/admins/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        queryParams: {
            search: {
                refreshModel: true
            },
            page: {
                refreshModel: true
            }
        },

        model: function model(params) {
            var query = params.search ? params.search : undefined;

            return this.store.query('admin', { query: query, offset: params.page * 30, limit: 50 });
        },

        actions: {
            willTransition: function willTransition() {
                this.controller.set('page', 0);
            }
        }

    });
});
define('www/routes/cms/companies/edit', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        controllerName: "cms/companies/form",

        renderTemplate: function renderTemplate() {
            this.render('cms/companies/form');
        },

        actions: {

            willTransition: function willTransition() {
                this.controller.reset();
                this.controller.get('model').rollbackAttributes();
            },

            didTransition: function didTransition() {
                this.controllerFor('cms').set('hideMenu', true);
            }
        }

    });
});
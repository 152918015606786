define('www/routes/cms/offers/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        queryParams: {
            category: {
                refreshModel: true
            },
            search: {
                refreshModel: true
            },
            type: {
                refreshModel: true
            },
            page: {
                refreshModel: true
            },
            valid: {
                refreshModel: true
            },
            active: {
                refreshModel: true
            }
        },

        model: function model(params) {
            var query = params.search ? params.search : undefined;
            var category = params.category ? params.category : undefined;
            var singleUse = params.type ? params.type : undefined;
            var valid = params.valid ? params.valid : undefined;

            var all = valid || singleUse ? undefined : "1";
            var days = '1,2,3,4,5,6,7';

            return this.store.query('offer', { query: query, category: category, offset: params.page * 30, limit: 30, singleUse: singleUse, valid: valid, active: params.active, all: all, days: days });
        },

        actions: {
            willTransition: function willTransition() {
                this.controller.set('page', 0);
            }
        }
    });
});
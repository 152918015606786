define('www/routes/cms/offers/new', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
    exports['default'] = _ember['default'].Route.extend({

        controllerName: "cms/offers/form",

        renderTemplate: function renderTemplate() {
            this.render('cms/offers/form');
        },

        model: function model() {
            return _ember['default'].RSVP.hash({
                offer: this.store.createRecord('offer'),
                companies: this.store.query('company', { limit: 10000, offset: 0 }),
                categories: this.store.findAll('category'),
                weekdays: this.store.findAll('weekday'),
                usergroups: this.store.findAll('group')
            });
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model);

            controller.set('today', (0, _moment['default'])().toDate());
        },

        actions: {

            save: function save() {
                var _this = this;

                this.controller.set('isLoading', true);

                this.controller.get('model.offer').save().then(function () {
                    _this.transitionTo('cms.offers');
                })['catch'](function (response) {
                    console.log(response);
                    _this.controller.set('errorMessage', response);
                })['finally'](function () {
                    _this.controller.set('isLoading');
                });
            },

            willTransition: function willTransition() {
                this.controller.get('model.offer').rollbackAttributes();
            },

            didTransition: function didTransition() {
                this.controllerFor('cms').set('hideMenu', true);
            }
        }

    });
});
define('www/models/group', ['exports', 'ember', 'ember-data', 'www/mixins/prevent-save-model', 'ember-data/relationships'], function (exports, _ember, _emberData, _wwwMixinsPreventSaveModel, _emberDataRelationships) {
    var attr = _emberData['default'].attr;
    var Model = _emberData['default'].Model;
    exports['default'] = Model.extend(_wwwMixinsPreventSaveModel['default'], {
        groupName: attr('string'),
        active: attr('boolean', { defaultValue: true }),
        imports: attr(),
        importStats: attr(),

        groupUsers: (0, _emberDataRelationships.hasMany)('user'),

        title: _ember['default'].computed('groupName', 'groupUsers.length', function () {
            return this.get('groupName') + ' (' + this.get('groupUsers.length') + ')';
        })
    });
});
define('www/routes/cms/notifications/new', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        controllerName: "cms/notifications/form",

        renderTemplate: function renderTemplate() {
            this.render('cms/notifications/form');
        },

        model: function model(params) {
            return _ember['default'].RSVP.hash({
                notification: this.store.createRecord('notification', {
                    notificationType: 'SIMPLE_TEXT',
                    os: 'BOTH'
                }),
                categories: this.store.findAll('category'),
                offers: this.store.query('offer', { query: params.query }),
                usergroups: this.store.findAll('group')
            });
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model);
        },

        actions: {

            save: function save() {
                var _this = this;

                this.controller.get('model.notification').create(this.controller.get('model.notification').serialize()).then(function () {
                    _this.transitionTo('cms.notifications');
                })['catch'](function (response) {
                    _this.controller.set('errorMessage', response);
                });
            },

            willTransition: function willTransition() {
                this.controller.get('model.notification').rollbackAttributes();
            },

            didTransition: function didTransition() {
                this.controllerFor('cms').set('hideMenu', true);
            }
        }

    });
});
define('www/controllers/cms/offers/form', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
    var Controller = _ember['default'].Controller;
    var observer = _ember['default'].observer;
    var computed = _ember['default'].computed;
    exports['default'] = Controller.extend({

        checkEnd: observer('model.offer.validFrom', function () {
            if (this.get('model.offer.validFrom')) {
                var start = (0, _moment['default'])(this.get('model.offer.validFrom')).toDate();

                this.set('end_min', start);
            }
        }),

        formOk: computed('model.offer.offerLine', 'model.offer.description', 'model.offer.offerStores.length', 'model.offer.offerCompany.id', 'model.offer.offerCategories.length', 'model.offer.validFrom', 'model.offer.validTo', function () {
            return this.get('model.offer.offerLine') && this.get('model.offer.description') && this.get('model.offer.offerStores.length') && this.get('model.offer.offerCompany.id') && this.get('model.offer.offerCategories.length') && this.get('model.offer.validFrom') && this.get('model.offer.validTo');
        }),

        actions: {

            goBack: function goBack() {
                window.history.back();
            },

            selectType: function selectType(type) {
                var singleUse = type === "1" ? true : false;
                this.set('model.offer.singleUse', singleUse);
            },

            selectCompany: function selectCompany(id) {
                var company = undefined;

                this.get('model.offer.offerStores').clear();

                if (id) {
                    company = this.store.peekRecord('company', id);

                    if (company.get('companyStores.length') === 1) {
                        this.get('model.offer.offerStores').pushObject(company.get('companyStores.firstObject'));
                    }
                }

                this.set('model.offer.offerCompany', company);
            },

            selectCategory: function selectCategory(id) {
                this.get('model.offer.offerCategories').clear();

                if (id) {
                    var category = this.store.peekRecord('category', id);
                    this.get('model.offer.offerCategories').pushObject(category);
                }
            },

            didSelectFiles: function didSelectFiles(model, files) {
                if (files.length) {
                    var reader = new FileReader();
                    reader.readAsDataURL(files[0]);

                    reader.addEventListener("load", function () {
                        var image = new Image();

                        image.onload = function () {
                            if (this.width >= 400 && this.height >= 300) {
                                model.set('picture', reader.result);
                                model.set('imageError');
                            } else {
                                model.set('imageError', 'Image is too small');
                            }
                        };

                        image.src = reader.result;
                    }, false);
                }
            },

            selectStart: function selectStart(date) {
                this.set('model.offer.validFrom', (0, _moment['default'])(date).valueOf());
            },

            selectEnd: function selectEnd(date) {
                this.set('model.offer.validTo', (0, _moment['default'])(date).valueOf());
            },

            askToDelete: function askToDelete() {
                this.toggleProperty('toggleDelete');
            },

            toggleStore: function toggleStore(store) {
                var stores = this.get('model.offer.offerStores');

                if (stores.includes(store)) {
                    stores.removeObject(store);
                } else {
                    stores.pushObject(store);
                }
            },

            toggleWeekday: function toggleWeekday(weekday) {
                var weekdays = this.get('model.offer.offerWeekdays');

                if (weekdays.includes(weekday)) {
                    weekdays.removeObject(weekday);
                } else {
                    weekdays.pushObject(weekday);
                }
            },

            toggleGroup: function toggleGroup(group) {
                var groups = this.get('model.offer.offerGroups');

                if (groups.includes(group)) {
                    groups.removeObject(group);
                } else {
                    groups.pushObject(group);
                }
            }
        }

    });
});
define('www/routes/forgotpsw', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        resetController: function resetController(controller, isExiting) {
            if (isExiting) {
                controller.reset();
            }
        }

    });
});
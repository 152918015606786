define('www/serializers/offer', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
    // import Serializer from './application';

    exports['default'] = _emberData['default'].RESTSerializer.extend(_emberData['default'].EmbeddedRecordsMixin, {
        attrs: {
            offerCompany: { embedded: 'always' },
            offerCategories: { embedded: 'always' },
            offerStores: { embedded: 'always' },
            offerWeekdays: { embedded: 'always' },
            offerGroups: { embedded: 'always' }
        },

        serialize: function serialize(snapshot, options) {
            var json = this._super(snapshot, options);
            if (json.id) {
                json.id = parseInt(json.id);
            }
            return json;
        },

        serializeIntoHash: function serializeIntoHash(hash, type, snapshot, options) {
            _ember['default'].assign(hash, this.serialize(snapshot, options));
        },

        normalizeSaveResponse: function normalizeSaveResponse(store, primaryModelClass, payload, id, requestType) {
            payload = {
                offer: payload
            };
            return this._super(store, primaryModelClass, payload, id, requestType);
        }
    });
});
define('www/controllers/cms/admins/form', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
    exports['default'] = _ember['default'].Controller.extend({

        today: (0, _moment['default'])().toDate(),

        formOk: _ember['default'].computed('model.name', 'model.email', 'model.password', function () {
            return this.get('model.name') && this.get('model.email') && (this.get('model.password.length') >= 6 || this.get('model.id') && !this.get('model.password'));
        }),

        actions: {}

    });
});
define('www/components/search-input', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        didInsertElement: function didInsertElement() {
            this.$('input').focus();
        },

        actions: {
            search: function search() {
                this.get('action')();
            }
        }

    });
});
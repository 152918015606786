define('www/routes/cms', ['exports', 'ember', 'ember-local-storage'], function (exports, _ember, _emberLocalStorage) {
    exports['default'] = _ember['default'].Route.extend({

        currentUser: (0, _emberLocalStorage.storageFor)('current-user'),

        checkSession: _ember['default'].observer('currentUser.session', function () {
            if (!this.get('currentUser.session')) {
                this.transitionTo('login');
            }
        }),

        beforeModel: function beforeModel() {
            if (!this.get('currentUser.session')) {
                this.transitionTo('login');
            }
        },

        actions: {
            didTransition: function didTransition() {
                this.controller.set('hideMenu');
            }
        }

    });
});
define('www/serializers/category', ['exports', 'ember-data', 'www/serializers/application'], function (exports, _emberData, _wwwSerializersApplication) {
    exports['default'] = _wwwSerializersApplication['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {
        normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
            payload.map(function (category) {
                var offersCount = category.newOffersCount;
                delete category.newOffersCount;
                category.offersCount = offersCount;
            });
            return this._super(store, primaryModelClass, payload, id, requestType);
        }
    });
});
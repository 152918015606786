define('www/components/modal-dialog', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        didInsertElement: function didInsertElement() {
            _ember['default'].$('body').addClass('with-modal');
        },

        didDestroyElement: function didDestroyElement() {
            _ember['default'].$('body').removeClass('with-modal');
        },

        actions: {
            action: function action() {
                this.get('action')();
            },

            close: function close() {
                this.set('close');
            }
        }

    });
});
define('www/adapters/application', ['exports', 'ember', 'ember-data/adapters/json-api', 'www/config/environment', 'ember-local-storage'], function (exports, _ember, _emberDataAdaptersJsonApi, _wwwConfigEnvironment, _emberLocalStorage) {
    var RESTURL = _wwwConfigEnvironment['default'].RESTURL;
    var NAMESPACE = _wwwConfigEnvironment['default'].NAMESPACE;
    exports['default'] = _emberDataAdaptersJsonApi['default'].extend({
        currentUser: (0, _emberLocalStorage.storageFor)('current-user'),
        // router: Ember.inject.service('-routing'),

        host: RESTURL,
        namespace: NAMESPACE,

        headers: _ember['default'].computed("currentUser.user.token", function () {
            return {
                "Accept": "application/json, text/javascript, */*; q=0.01",
                "Content-Type": "application/json",
                "Authorization": this.get("currentUser.user.token") ? "Bearer " + this.get("currentUser.user.token") : ''
            };
        }),

        // turn off pluralization

        // pathForType(type) {
        //     var camelized = Ember.String.camelize(type);
        //     return Ember.String.singularize(camelized);
        // },

        isInvalid: function isInvalid(status) {
            // console.log(status, headers, payload);

            if (status === 401) {
                this.get('currentUser').reset();
            }

            return this._super.apply(this, arguments);
        }
    });
});
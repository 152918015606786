define('www/components/main-header', ['exports', 'ember', 'ember-local-storage'], function (exports, _ember, _emberLocalStorage) {
    exports['default'] = _ember['default'].Component.extend({
        currentUser: (0, _emberLocalStorage.storageFor)('current-user'),
        loginManager: _ember['default'].inject.service(),

        actions: {
            logout: function logout() {
                this.get('loginManager').logout();
            }
        }
    });
});
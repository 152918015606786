define('www/controllers/forgotpsw', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        queryParams: ['email', 'token'],
        loginManager: _ember['default'].inject.service(),

        init: function init() {
            this.set('admin', this.store.createRecord('admin'));
        },

        reset: function reset() {
            this.set('email');
            this.set('token');
            this.set('errorMessage');
            this.set('successMessage');
        },

        actions: {
            sendRequest: function sendRequest() {
                var _this = this;

                var data = {
                    email: this.get('email')
                };

                this.get('admin').forgotpsw(data).then(function () {
                    _this.set('successMessage', 'Við höfum sent tölvupóst með hlekk á ' + data.email + ', athugaðu að það gæti tekið smá stund að berast.');
                    _this.set('errorMessage');
                    _this.set('email');
                })['catch'](function () {
                    _this.set('errorMessage', 'Þetta netfang er ekki á skrá.');
                    _this.set('successMessage');
                });
            },

            sendPsw: function sendPsw() {
                var _this2 = this;

                var hash = this.get('loginManager').hashPsw(this.get('password'));

                var data = {
                    password: hash,
                    // password: this.get('password'),
                    token: this.get('token')
                };

                this.get('admin').changepsw(data).then(function () {
                    _this2.set('successMessage', 'Lykilorði hefur verið breytt, þú verður færð/ur á innskráningasíðu.');
                    _this2.set('errorMessage');

                    _ember['default'].run.later(_this2, function () {
                        this.transitionToRoute('login');
                    }, 3000);
                })['catch'](function () {
                    _this2.set('errorMessage', 'Beiðni um breytingu lykilorðs er útrunninn, vinsamlegast byrjaðu upp á nýtt.');
                    _this2.set('successMessage');
                });
            }
        }

    });
});
define('www/routes/cms/usergroups/new', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        controllerName: "cms/usergroups/form",

        renderTemplate: function renderTemplate() {
            this.render('cms/usergroups/form');
        },

        model: function model() {
            return this.store.createRecord('group');
        },

        actions: {

            save: function save() {
                var _this = this;

                this.controller.set('isLoading', true);

                this.controller.get('model').save().then(function () {

                    if (_this.controller.get('model.imports.length')) {
                        _this.controller.set('model.imports');
                        _this.controller.set('model.filename');
                        // save changes in the store... otherwise they will be loaded back on transition.
                        _this.controller.get('model').saveWithoutSave();
                    }

                    _this.transitionTo('cms.usergroups');
                })['catch'](function (response) {
                    console.log(response);
                })['finally'](function () {
                    _this.controller.set('isLoading');
                });
            },

            willTransition: function willTransition() {
                this.controller.get('model').rollbackAttributes();
                this.controller.set('errorMessage');
            },

            didTransition: function didTransition() {
                this.controllerFor('cms').set('hideMenu', true);
            }
        }

    });
});
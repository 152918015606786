define('www/services/login-manager', ['exports', 'ember', 'cryptojs', 'ember-local-storage'], function (exports, _ember, _cryptojs, _emberLocalStorage) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Service.extend({

        store: service(),
        apiManager: service(),
        currentUser: (0, _emberLocalStorage.storageFor)('current-user'),

        hashPsw: function hashPsw(psw) {
            var hash = _cryptojs['default'].SHA1(psw).toString();
            return hash;
        },

        logUser: function logUser(user) {
            this.set('currentUser.user', user);
            this.set('currentUser.session', true);
        },

        login: function login(email, password, callback) {
            var _this = this;

            var hash = this.hashPsw(password);

            var data = {
                type: 'post',
                url: '/admins/login',
                data: {
                    email: email,
                    // password: password,
                    password: hash
                }
            };

            this.get('apiManager').request(data).then(function (response) {
                _this.logUser(response);
                callback();
            })['catch'](function (error) {
                console.log(error);
                callback(error ? error.message : 'Server error.');
            });
        },

        logout: function logout() {
            var _this2 = this;

            var data = {
                url: '/admins/logout'
            };

            this.get('apiManager').request(data)['finally'](function () {
                _this2.get('currentUser').reset();
                _this2.get('store').unloadAll();
            });
        }

    });
});
// requestPsw(email, callback) {
//     let data = {
//         type: 'post',
//         url: '/admins/forgotpsw',
//         data: {
//             email: email,
//         },
//     };
//
//     this.get('apiManager').request(data, (response) => {
//         if (!response || response.status) {
//             console.log(response);
//             callback(response ? response.message : 'Server error.');
//         } else {
//             callback();
//         }
//     });
// },
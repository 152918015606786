define('www/storages/current-user', ['exports', 'ember-local-storage/session/object'], function (exports, _emberLocalStorageSessionObject) {

    var Storage = _emberLocalStorageSessionObject['default'].extend();

    Storage.reopenClass({
        initialState: function initialState() {
            return { user: {}, session: false };
        }
    });

    exports['default'] = Storage;
});
define('www/routes/cms/admins/new', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    var Route = _ember['default'].Route;
    exports['default'] = Route.extend({

        loginManager: service(),
        controllerName: 'cms/admins/form',

        renderTemplate: function renderTemplate() {
            this.render('cms/admins/form');
        },

        model: function model() {
            return this.store.createRecord('admin');
        },

        actions: {

            save: function save() {
                var _this = this;

                this.controller.set('model.password', this.get('loginManager').hashPsw(this.controller.get('model.password')));

                this.controller.get('model').register(this.controller.get('model')).then(function () {
                    _this.controller.set('model.password');
                    _this.transitionTo('cms.admins');
                })['catch'](function (response) {
                    if (response.errors[0].status === "409") {
                        _this.controller.set('errorMessage', 'Netfang er þegar í notkun');
                    }
                });
            },

            willTransition: function willTransition() {
                this.controller.get('model').rollbackAttributes();
                this.controller.set('errorMessage');
            },

            didTransition: function didTransition() {
                this.controllerFor('cms').set('hideMenu', true);
            }
        }

    });
});
define('www/controllers/cms/companies/form', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
    var service = _ember['default'].inject.service;
    var Controller = _ember['default'].Controller;
    var computed = _ember['default'].computed;
    exports['default'] = Controller.extend({

        apiManager: service(),

        storeOk: computed('store.address', 'store.latitude', 'store.longitude', function () {
            return this.get('store.address') && this.isGeoloc(this.get('store.latitude')) && this.isGeoloc(this.get('store.longitude'));
        }),

        formOk: computed('model.name', 'model.ssn', 'stores.length', 'model.companyStores.length', function () {
            return this.get('model.name') && this.get('model.ssn') && (this.get('stores.length') || this.get('model.companyStores.length'));
        }),

        isGeoloc: function isGeoloc(value) {
            var match = value ? value.match(/^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}/) : null;
            return match ? match[0] : null;
        },

        reset: function reset() {
            this.store.unloadAll('companyStore');
            this.resetStore();
        },

        resetStore: function resetStore() {
            this.set('store.address');
            this.set('store.latitude');
            this.set('store.longitude');
        },

        removeStore: function removeStore(store) {
            var stores = this.get('model.companyStores');

            stores.removeObject(store);
        },

        actions: {

            selectDate: function selectDate(date) {
                this.set('model.date', (0, _moment['default'])(date).format('YYYY-MM-DD'));
            },

            didSelectFiles: function didSelectFiles(model, files) {
                if (files.length) {
                    var reader = new FileReader();
                    reader.readAsDataURL(files[0]);

                    reader.addEventListener("load", function () {
                        var image = new Image();

                        image.onload = function () {
                            if (this.width >= 150 && this.height >= 150) {
                                model.set('logoUrl', reader.result);
                                model.set('imageError');
                            } else {
                                model.set('imageError', 'Image is too small');
                            }
                        };

                        image.src = reader.result;
                    }, false);
                }
            },

            addStore: function addStore(rawStore) {
                var store = this.store.createRecord('companyStore', {
                    address: rawStore.get('address'),
                    latitude: rawStore.get('latitude'),
                    longitude: rawStore.get('longitude')
                });

                this.get('model.companyStores').pushObject(store);

                this.resetStore();
            },

            editStore: function editStore(store) {
                this.set('storeToEdit', store.get('id'));
            },

            saveStore: function saveStore(store) {
                var _this = this;

                if (store.get('id')) {
                    store.save().then(function () {
                        _this.set('storeToEdit');
                    });
                } else {
                    this.set('storeToEdit');
                }
            },

            removeStore: function removeStore(store) {
                if (store.get('offers.length')) {
                    this.set('storeToDelete', store);
                    this.toggleProperty('toggleDelete');
                } else {
                    this.removeStore(store);
                }
            },

            removeStoreConfirmed: function removeStoreConfirmed() {
                this.removeStore(this.get('storeToDelete'));
                this.set('storeToDelete');
                this.set('toggleDelete');
            },

            save: function save() {
                var _this2 = this;

                var company = this.get('model');

                var type = company.get('id') ? 'PUT' : 'POST';
                var id = company.get('id') ? company.get('id') : '';
                var stores = company.get('id') ? company.get('companyStores').toArray() : [];

                var data = {
                    type: type,
                    url: '/companies/' + id,
                    data: {
                        company: company.serialize({ includeId: true }),
                        stores: stores
                    }
                };

                this.set('isLoading', true);

                this.get('apiManager').request(data).then(function () {
                    _this2.transitionToRoute('cms.companies');
                })['catch'](function (response) {
                    console.log(response);
                    _this2.set('errorMessage', response);
                })['finally'](function () {
                    _this2.set('isLoading');
                });
            }
        }

    });
});
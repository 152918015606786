define('www/app', ['exports', 'ember', 'www/resolver', 'ember-load-initializers', 'www/config/environment'], function (exports, _ember, _wwwResolver, _emberLoadInitializers, _wwwConfigEnvironment) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _wwwConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _wwwConfigEnvironment['default'].podModulePrefix,
    Resolver: _wwwResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _wwwConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});
define('www/mixins/prevent-save-model', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    saveWithoutSave: function saveWithoutSave() {
      var modelName = this.constructor.modelName;
      var adapter = this.store.adapterFor(modelName);

      adapter.set('preventSave', true);
      return this.save();
    }
  });
});
define('www/controllers/cms/offers/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        queryParams: ['category', 'type', 'search', 'page', 'valid', 'active'],
        category: "",
        type: "",
        valid: "",
        active: "1",
        search: "",
        page: 0,

        categories: [{ id: "1", name: "Áhugamál og tómstundir" }, { id: "2", name: "Bílar" }, { id: "3", name: "Blóm og gjafavörur" }, { id: "4", name: "Börn" }, { id: "5", name: "Fatnaður" }, { id: "6", name: "Gisting" }, { id: "7", name: "Hár og snyrting" }, { id: "8", name: "Heilsa og útlit" }, { id: "9", name: "Heimilið" }, { id: "10", name: "Úr og skart" }, { id: "11", name: "Veitingastaðir" }],

        searchChanged: _ember['default'].observer('search', 'category', function () {
            this.set('page', 0);
        }),

        scrollUp: function scrollUp() {
            _ember['default'].$("html, body").animate({ scrollTop: _ember['default'].$('.bold').position().top }, 200);
        },

        actions: {
            search: function search() {},

            selectCategory: function selectCategory(id) {
                this.set('category', id);
            },

            loadLess: function loadLess() {
                this.set('page', this.get('page') - 1);
                this.scrollUp();
            },
            loadMore: function loadMore() {
                this.set('page', this.get('page') + 1);
                this.scrollUp();
            }
        }

    });
});
define('www/routes/cms/notifications/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        queryParams: {
            search: {
                refreshModel: true
            },
            type: {
                refreshModel: true
            },
            page: {
                refreshModel: true
            }
        },

        model: function model() {
            // let query = params.search ? params.search : undefined;
            // let type = params.type ? params.type : undefined;

            return this.store.findAll('notification');
        },

        actions: {
            willTransition: function willTransition() {
                this.controller.set('page', 0);
            }
        }
    });
});
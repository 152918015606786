define('www/routes/company/report', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Route.extend({

        apiManager: service(),

        beforeModel: function beforeModel(transition) {
            if (transition.queryParams.token) {
                transition.queryParams.token = transition.queryParams.token.split(' ').join('+');
            }
            this.set('token', transition.queryParams.token);
        },

        model: function model(params) {
            var _this = this;

            this.set('companyId', params.companyId);

            return _ember['default'].RSVP.hash({
                company: this.get('apiManager').getCompanyData(this.get('token'), params.companyId),
                offers: this.get('apiManager').getCompanyOffers(this.get('token'), params.companyId).then(function (res) {
                    res.map(function (offer) {
                        // We use store to peek single offer record by id in the controller.
                        _this.store.createRecord('offer', {
                            id: offer.id,
                            singleUse: offer.singleUse,
                            title: offer.title,
                            validFrom: offer.validFrom,
                            validTo: offer.validTo
                        });
                    });
                    return res;
                })
            });
        },

        actions: {

            loadOfferReport: function loadOfferReport(offerId, dateKey) {
                var _this2 = this;

                if (!dateKey || !Number(offerId)) {
                    return;
                }

                this.get('apiManager').getOfferReport(this.get('token'), this.get('companyId'), offerId).then(function (response) {
                    var offerReport = _this2.store.createRecord('report', {
                        count: response[dateKey].nbrActivations,
                        android: response[dateKey].android,
                        ios: response[dateKey].iOS
                    });
                    _this2.controller.set('currentOfferReport', offerReport);
                });
            }

        }

    });
});
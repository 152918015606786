define('www/models/offer', ['exports', 'ember', 'ember-data', 'ember-data/relationships', 'moment'], function (exports, _ember, _emberData, _emberDataRelationships, _moment) {
    var attr = _emberData['default'].attr;
    var Model = _emberData['default'].Model;
    exports['default'] = Model.extend({
        title: attr('string'),
        offerLine: attr('string'),
        description: attr('string'),
        picture: attr('string'),
        validFrom: attr('number'),
        validTo: attr('number'),
        singleUse: attr('boolean', { defaultValue: false }),
        active: attr('boolean', { defaultValue: true }),

        offerCategories: (0, _emberDataRelationships.hasMany)('category'),
        offerCompany: (0, _emberDataRelationships.belongsTo)('company'),
        offerStores: (0, _emberDataRelationships.hasMany)('companyStore'),
        offerWeekdays: (0, _emberDataRelationships.hasMany)('weekday'),
        offerGroups: (0, _emberDataRelationships.hasMany)('group'),

        validFrom_format: _ember['default'].computed('validFrom', function () {
            return '' + (0, _moment['default'])(this.get('validFrom'), 'x').format("MM/DD/YYYY");
        }),

        validTo_format: _ember['default'].computed('validTo', function () {
            return '' + (0, _moment['default'])(this.get('validTo'), 'x').format("MM/DD/YYYY");
        })
    });
});
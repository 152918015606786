define('www/routes/cms/companies/new', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        controllerName: "cms/companies/form",

        renderTemplate: function renderTemplate() {
            this.render('cms/companies/form');
        },

        model: function model() {
            return this.store.createRecord('company');
        },

        actions: {

            // save() {
            //     this.controller.set('errorMessage');
            //     this.controller.set('isLoading', true);
            //
            //     this.controller.get('model').save().then(() => {
            //         this.transitionTo('cms.companies');
            //     }).catch((response) => {
            //         this.controller.set('errorMessage', response);
            //     }).finally(() => {
            //         this.controller.set('isLoading');
            //         this.transitionTo('cms.companies');
            //     });
            // },

            willTransition: function willTransition() {
                this.controller.get('model').rollbackAttributes();
            },

            didTransition: function didTransition() {
                this.controllerFor('cms').set('hideMenu', true);
            }
        }

    });
});
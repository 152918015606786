define('www/controllers/cms/users/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        queryParams: ['search', 'page'],
        search: "",
        page: 0,

        searchChanged: _ember['default'].observer('search', function () {
            this.set('page', 0);
        }),

        scrollUp: function scrollUp() {
            _ember['default'].$("html, body").animate({ scrollTop: _ember['default'].$('.bold').position().top }, 200);
        },

        actions: {
            search: function search() {
                if (this.get('search')) {
                    console.log(this.get('search'));
                }
            },

            loadLess: function loadLess() {
                this.set('page', this.get('page') - 1);
                this.scrollUp();
            },
            loadMore: function loadMore() {
                this.set('page', this.get('page') + 1);
                this.scrollUp();
            }
        }

    });
});
define('www/routes/cms/pictures', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        model: function model() {
            return _ember['default'].RSVP.hash({
                login: this.store.findRecord('picture', 'login'),
                facebook: this.store.findRecord('picture', 'facebook'),
                registration: this.store.findRecord('picture', 'registration')
            });
        },

        actions: {

            save: function save() {
                var login = this.controller.get('model.login');
                var facebook = this.controller.get('model.facebook');
                var registration = this.controller.get('model.registration');

                if (login.get('hasDirtyAttributes')) {
                    login.save();
                }

                if (facebook.get('hasDirtyAttributes')) {
                    facebook.save();
                }

                if (registration.get('hasDirtyAttributes')) {
                    registration.save();
                }
            }

        }

    });
});
define('www/models/user', ['exports', 'ember', 'ember-data', 'moment'], function (exports, _ember, _emberData, _moment) {
    var attr = _emberData['default'].attr;
    var Model = _emberData['default'].Model;
    exports['default'] = Model.extend({
        customerName: attr('string'),
        email: attr('string'),
        ssn: attr('string'),
        phone: attr('string'),

        //device: attr('string'),
        fbLogin: attr('boolean'),
        arionCustomer: attr('boolean', { defaultValue: false }),
        expiryDate: attr('number'),

        expiryDate_format: _ember['default'].computed('expiryDate', function () {
            return (0, _moment['default'])(this.get('expiryDate'), 'x').format("MM/DD/YYYY");
        })

    });
});
define('www/models/notification', ['exports', 'ember', 'ember-data', 'moment', 'ember-api-actions', 'ember-data/relationships'], function (exports, _ember, _emberData, _moment, _emberApiActions, _emberDataRelationships) {
    var attr = _emberData['default'].attr;
    var Model = _emberData['default'].Model;
    exports['default'] = Model.extend({
        created: attr('string'),
        message: attr('string'),
        label: attr('string'),
        processDate: attr('string'),
        os: attr('string'),
        notificationType: attr('string'),
        value: attr('string'),
        processed: attr('boolean'),

        groups: (0, _emberDataRelationships.hasMany)('group'),

        display: _ember['default'].computed('created', 'processDate', 'message', function () {

            var notificationType = undefined;

            switch (this.get('notificationType')) {
                case 'SIMPLE_TEXT':
                    notificationType = 'Almenn tilkynning';
                    break;
                case 'NEW_OFFERS_IN_CATEGORY':
                    notificationType = 'Ný tilboð úr völdum flokki';
                    break;
                case 'SPECIFIC_OFFER':
                    notificationType = 'Valið tilboð';
                    break;
            }

            return {
                created: (0, _moment['default'])(this.get('created'), 'x').format('Do MMMM YYYY'),
                processDate: (0, _moment['default'])(this.get('processDate'), 'x').format('Do MMMM YYYY HH:mm'),
                notificationType: notificationType
            };
        }),

        create: (0, _emberApiActions.collectionAction)({ path: 'send', type: 'post' })
    });
});
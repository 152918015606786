define('www/routes/index', ['exports', 'ember', 'ember-local-storage'], function (exports, _ember, _emberLocalStorage) {
    exports['default'] = _ember['default'].Route.extend({

        currentUser: (0, _emberLocalStorage.storageFor)('current-user'),

        beforeModel: function beforeModel() {
            if (this.get('currentUser.session')) {
                this.transitionTo('cms.users');
            } else {
                this.transitionTo('login');
            }
        }

    });
});
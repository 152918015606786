define('www/routes/cms/usergroups/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        model: function model() {
            return this.store.findAll('group');
        },

        actions: {}

    });
});